<template>
  <div class="about_inner">
    <div class="left">
      <div class="image">
        <img src="../../assets/img/about/1.jpeg" alt="about" />
        <div
          class="main"
          v-scroll-reveal="{ delay: 20 }"
          :style="{ backgroundImage: 'url(' + aboutImg + ')' }"
        ></div>
      </div>
      <!-- End image  -->
    </div>
    <!-- End left -->

    <div class="right">
      <div class="shane_tm_title">
        <span v-scroll-reveal="{ delay: 20 }">Über mich</span>
        <h3 v-scroll-reveal="{ delay: 40 }">
          Wer ich bin
        </h3>
      </div>
      <div class="text" v-scroll-reveal="{ delay: 60 }">
        <p>
          Ich bin 25 Jahre alt und im Kanton Luzern, Hohenrain, aufgewachsen
          und bis heute dort wohnhaft.
          Ich arbeite als Leiter Analytics und Prozesse beim VZ VermögensZentrum in Zug und engagiere mich nebenamtlich als Gemeinderat
          in Hohenrain sowie als Verbandsleitungsmitglied der Idee Seetal.
        </p>
        </div>
        <div class="text" v-scroll-reveal="{ delay: 60 }">
        <p>
        Ich bin motiviert und freue mich, dass ich für die FDP als Kantonsratskandidaten an die Wahlen im April antreten darf. Ich werde mich für einen vorwärts gerichteten und innovativen Kanton Luzern einsetzen und bin überzeugt, dass wir so die aktuellen sowie zukünftige Herausforderungen meistern können.
        </p>
      </div>
    </div>
    <!-- End right -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      aboutImg: require("../../assets/img/about/1.jpeg"),
      cv: require("../../assets/img/resume/resume.jpg"),
    };
  },
};
</script>
