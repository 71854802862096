<template>
  <div class="shane_tm_section">
    <div class="shane_tm_copyright">
      <div class="container">
        <div class="inner">
          <p>
            &copy; {{ new Date().getFullYear() }}
            <a
              target="_blank"
              rel="noreferrer"
            >
              Jonas Roth
            </a>
          </p>
        </div>
        <!-- End inner -->
      </div>
    </div>
    <!-- End shane_tm_copyright -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
