<template>
  <div class="home-two">
    <Header />
    <!-- End Header Section -->

    <Slider />
    <!-- End Slider Section -->

    <div class="shane_tm_section" id="about">
      <div class="shane_tm_about">
        <div class="container">
          <About />
        </div>
        <!--  End container -->
      </div>
    </div>
    <!-- End About Section -->

    <CallToActions />
    <!-- End  call to actions -->

    <Footer />
    <!-- End  footer  -->
  </div>
</template>

<script>
import Header from "../../components/Header";
import Slider from "../../components/slider/SliderTwo";
import About from "../../components/about/AboutTwo";
import CallToActions from "../../components/calltoactions/CallToActionsTwo";
import Footer from "../../components/Footer";
export default {
  components: {
    Header,
    Slider,
    About,
    CallToActions,
    Footer,
  },
};
</script>

<style lang="scss" scoped></style>
