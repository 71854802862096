<template>
  <ul class="social social-default position-relative">
    <li v-for="(social, i) in socialList" :key="i">
      <a :href="social.link" target="_blank" rel="noreferrer"
        ><img class="svg" :src="social.src" alt="social"
      /></a>
    </li>
  </ul>
  <!-- End. social -->
</template>

<script>
export default {
  data() {
    return {
      socialList: [
        {
          link: "https://www.linkedin.com/in/jonas-roth-69057a168/",
          src: require("../assets/img/svg/social/linkedin.svg"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
